<script setup lang="ts"></script>

<template>
    <div class="layout-no-nav">
        <TheHeader class="layout-no-nav__header" />

        <main class="layout-no-nav__content">
            <slot />
        </main>
    </div>
</template>

<style lang="scss">
.layout-no-nav {
    min-height: 100dvh;
    display: grid;

    max-width: 768px;
    margin: auto;

    background-color: v(white);
    background-image: url('~/assets/bg/bg-blur-violett-orange.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    &__content {
        padding: 24px;
        padding-top: 130px;
        padding-bottom: 130px;
        flex: 1 1 100%;
    }
}
</style>
